import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	IonPage,
	IonCol,
	IonContent,
	IonGrid,
	IonRow,
	IonButton,
	IonModal,
	IonFooter,
	IonToolbar,
	IonButtons,
	IonHeader,
	IonCard,
	IonCardTitle,
	IonCardSubtitle,
	IonCardHeader,
	IonCardContent,
	useIonAlert,
	useIonLoading
} from '@ionic/react';

import newyorker from '../../Assets/news-logos/newyorker-stack.png'
import gothamist from '../../Assets/news-logos/gothamist.png'
import forbes from '../../Assets//news-logos/forbes.png'
import linkedin from '../../Assets/news-logos/linkedin.png'

import { FaApple } from "react-icons/fa";

import { DataContext } from '../../Providers/DataProvider';
import { deviceType } from 'react-device-detect';

import tableone from '../../Assets/tableone-gold.png'
import tableoneBlue from '../../Assets/tableone-notable-blue.png'
import hundredpluswhite from '../../Assets/100plus-white.png'


import './ViewNew.scoped.scss';

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewNewDesktop = () => {
	const queryParameters = new URLSearchParams(window.location.search)

	const [loadingQueryParams, setLoadingQueryParams] = useState(true);
	const [referrer, setReferrer] = useState(false);
	const [partner, setPartner] = useState(false);

	const {
		handleReferral,
		handlePartner,
		handleClick
	} = useContext(DataContext)

	const [localEmail, setLocalEmail] = useState('')

	// const [presentAlert, dismiss] = useIonToast();
	const [presentAlert] = useIonAlert();
	const [present, dismiss] = useIonLoading();

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	async function handleSubmit() {
		const queryParameters = new URLSearchParams(window.location.search)


		const referrer = queryParameters.get('referrer')
		console.log(localEmail, referrer.length)

		if (!validateEmail(localEmail) || referrer.length !== 36) {
			presentAlert({
				message: 'Valid email & referrer Required',
				duration: 1500,
				position: 'middle',
				cssClass: 'custom-alert',
				buttons: ['OK']
			});
			return;
		} else {
			await present('Saving referral')

			await handleReferral(
				referrer,
				localEmail
			)

			await dismiss()

			window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_self');
		}

	}

	useEffect(() => {
		async function handle() {
			const tmpReferrer = queryParameters.get('referrer');

			if (tmpReferrer?.length == 36) {
				setReferrer(tmpReferrer);
			}

			const tmpPartner = queryParameters.get('partner');
			if (tmpPartner) {
				setPartner(tmpPartner)
			}

			setLoadingQueryParams(false);
		}

		handle();
	}, [queryParameters])

	function toArticle(url) {
		window.open(url, '_blank');
	}

	async function toUrl(url) {
		window.open(url);
	};

	async function toDownload() {
		handleClick()

		// const os = getMobileOperatingSystem()

		window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');

		// if (os === "iOS") {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// } 
		// else if (os === "Android") {
		// 	window.open('https://play.google.com/store/apps/details?id=com.fyi.hooli.app', '_blank');
		// } 
		// else {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// }
	};

	useEffect(() => {
		async function handle() {
			await handlePartner(partner, 'desktop')
		}

		if (partner) {
			handle()
		}
	},[partner])

	return (
		<IonPage fullscreen>
			{/* <IonHeader style={{ '--background': 'transparent' }}>
				<IonGrid style={{ '--background': 'transparent' }}>
					<IonRow>
						<IonCol style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '1rem' }}>
							<img src={tableone} style={{ height: '4rem' }} />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonHeader> */}


			{/* <ModalSignup show={showSignup} setShow={setShowSignup} closeable={true} /> */}
			<IonContent fullscreen={true} style={{ '--background': 'black' }}>
				<IonGrid style={{ height: '100%', padding: 0, maxWidth: '125rem' }}>
					<IonRow style={{ height: '100vh' }} className='citybig'>
						<IonCol>
							<IonRow>
								<IonCol style={{ textAlign: 'left', marginTop: '2rem' }}>
									<img src={tableone} style={{ height: '3rem' }} />
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '30vh' }}>
								<IonCol style={{ textAlign: 'center' }}>

									<IonRow>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '3rem',
												color: '#F6F1EF'
											}}>
												Serving <span style={{ color: "#F2C498" }}>access</span> to the <span style={{ color: "#F2C498" }}>best</span> restaurants.
											</span>
										</IonCol>
									</IonRow>

									<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '1.4rem',
												color: '#F6F1EF'
											}}>
												Exclusively in New York City.
											</span>
										</IonCol>
									</IonRow>

									<IonRow>
										<IonCol>
											<IonButton
												onClick={() => (toDownload())}
												shape="round"
												style={{
													fontSize: '1.4rem',
													fontWeight: 600,
													height: '5rem',
													width: '13rem',
													'--padding-left': 0,
													'--background': '#F6F1EF',
													'--background-activated': '#F6F1EF',
													'--background-hover': '#F6F1EF',
													'--color': '#000000'

												}}
											>
												Download Now
											</IonButton>
										</IonCol>
									</IonRow>


									<IonRow style={{ 'marginTop': '10rem' }} className='vertical-align-content'>
										<IonCol sizeXs={0} sizeSm={0} sizeMd={1.5} />
										<IonCol size={1.5}>
											<img src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/forbes.png'} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://www.forbes.com/sites/lauriewerner/2024/04/27/mothers-day-gift-guide-2024-top-new-york-restaurant-gift-certificates'))} />
										</IonCol>
										<IonCol size={1.5}>
											<img src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/newyorker-stack.png'} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://www.newyorker.com/news/our-local-correspondents/why-you-cant-get-a-restaurant-reservation'))} />
										</IonCol>
										<IonCol size={3}>
											<img src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/100plus-white.png'} className='center-image' style={{ maxWidth: '24rem' }} />
										</IonCol>
										<IonCol size={1.5}>
											<img src={gothamist} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://gothamist.com/arts-entertainment/dinner-reservations-at-tatiana-are-impossible-to-get-so-i-spent-a-month-trying'))} />
										</IonCol>
										<IonCol size={1.5}>
											<img src={linkedin} className='center-image' style={{ maxHeight: deviceType === 'mobile' ? '3rem ' : '5rem', textAlign: 'center' }} />
										</IonCol>
										<IonCol sizeXs={0} sizeSm={0} sizeMd={1.5} />
									</IonRow>
								</IonCol>
							</IonRow>

						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#F6F1EF', textAlign: 'center' }}>
						<IonCol style={{ padding: 0 }}>
							<IonRow style={{ marginTop: '5vh', height: '60vh', }} className='center-row'>
								<IonCol>
									<IonRow>
										<IonCol size={1} />
										<IonCol style={{ height: '100%' }}>
											<IonRow >
												<IonCol>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '3rem',
														color: '#000000',
														fontWeight: 600
													}}>
														<span style={{ color: "#6633FF" }}>Platinum</span> card dining<br />without the card.
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
												<IonCol>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '2rem',
														color: '#000000'
													}}>
														We aggregate availability across all major reservation platforms, in true real-time to ensure you never miss a table and equip you with the fastest notify system in the market.
													</span>
												</IonCol>
											</IonRow>

											<IonRow>
												<IonCol>
													<IonButton
														onClick={() => (toDownload())}
														shape="round"
														style={{
															fontSize: '1.4rem',
															fontWeight: 600,
															height: '5rem',
															width: '13rem',
															'--padding-left': 0,
															'--background': '#000000',
															'--background-activated': '#000000',
															'--background-hover': '#000000',
															'--color': '#FFFFFF'

														}}
													>
														Download Now
													</IonButton>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={1} />

									</IonRow>
								</IonCol>


								<IonCol style={{ height: '100%' }} className='screenshots'>
									{/* <IonRow style={{ height: '40rem', marginTop: '3rem', marginBottom: '3rem' }} className='screenshots' /> */}

								</IonCol>
							</IonRow>


							<IonRow style={{ marginTop: '5vh', height: 'auto', }} className='center-row'>



								<IonCol style={{ height: '100%' }}>
									{/* <IonRow style={{ height: '40rem', marginTop: '3rem', marginBottom: '3rem' }} className='screenshots' /> */}
									<IonRow>
										<IonCol>
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/notifications.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left', height: '7.5rem' }}>
													<IonCardTitle>Notify</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left', height: '7.rem' }}>
													The fastest reservations alerts available.
												</IonCardContent>
											</IonCard>
										</IonCol>
										<IonCol>
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/tablechance.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left', height: '7.5rem' }}>
													<IonCardTitle>TableChance</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left', height: '7.rem' }}>
													Know your odds at finding a table.
												</IonCardContent>
											</IonCard>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/realtime.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left' }}>
													<IonCardTitle>Availability</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left' }}>
													Look for tables in real-time, no refreshing required.
												</IonCardContent>
											</IonCard>

										</IonCol>
										<IonCol>
											<IonCard style={{ height: '35rem' }}>
												<img alt="Silhouette of mountains" src={'https://dsf7gjhypfglc.cloudfront.net/images/tableone.app/images/best.png'} style={{ height: '20rem', width: '100%' }} />
												<IonCardHeader style={{ textAlign: 'left' }}>
													<IonCardTitle>Only the best</IonCardTitle>
												</IonCardHeader>
												<IonCardContent style={{ textAlign: 'left' }}>
													TableOne's restaurant list is entirely decided by its users.
												</IonCardContent>
											</IonCard>
										</IonCol>
									</IonRow>
								</IonCol>

								<IonCol>
									<IonRow>
										<IonCol size={1} />
										<IonCol style={{ height: '100%' }}>
											<IonRow >
												<IonCol>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '3rem',
														color: '#000000',
														fontWeight: 600
													}}>
														A booking experience<br />designed for <span style={{ color: "#6633FF" }}>you</span>.
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
												<IonCol>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '2rem',
														color: '#000000'
													}}>
														Never before seen filters and features to make finding your perfect table easier than its ever been.
													</span>
												</IonCol>
											</IonRow>

											<IonRow>
												<IonCol>
													<IonButton
														onClick={() => (toDownload())}
														shape="round"
														style={{
															fontSize: '1.4rem',
															fontWeight: 600,
															height: '5rem',
															width: '13rem',
															'--padding-left': 0,
															'--background': '#000000',
															'--background-activated': '#000000',
															'--background-hover': '#000000',
															'--color': '#FFFFFF'

														}}
													>
														Download Now
													</IonButton>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={1} />

									</IonRow>
								</IonCol>
							</IonRow>

						</IonCol>
					</IonRow>

					<IonRow
						style={{
							height: '75rem'
						}}
						className='news1'
					>
						<IonCol style={{ textAlign: 'center' }}>
							<IonRow style={{ height: '5rem' }} onClick={() => (toUrl("https://www.newyorker.com/news/our-local-correspondents/why-you-cant-get-a-restaurant-reservation"))} />

							<IonRow style={{ marginTop: '25rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '2rem',
										color: '#FFFFFF'
									}}>
										“Having an American Express Platinum Card may help...<br />
										walk-ins for bar seats can yield results.<br /><br />
										But the <span style={{ color: "#F2C498" }}>best</span> way to get these reservations is to<br /> download <span style={{ color: "#F2C498" }}>TableOne</span>.”<br /><br />
									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ height: '5rem' }} onClick={() => (toUrl("https://www.forbes.com/sites/lauriewerner/2024/04/27/mothers-day-gift-guide-2024-top-new-york-restaurant-gift-certificates"))} />
						</IonCol>
					</IonRow>


					<IonRow style={{ background: '#F6F1EF', height: 'auto', textAlign: 'center', paddingBottom: '4rem' }} className='center-row'>
						<IonCol>
							<IonRow>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '3rem',
										color: '#000000',
										fontWeight: 600
									}}>
										Elite dining is now<br /><span style={{ color: "#6633FF" }}>affordable</span>.
									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '0.4rem', marginBottom: '1.2rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '2rem',
										color: '#000000'
									}}>
										No costly annual fee or minimum spends required.
									</span>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonButton
										onClick={() => (toDownload())}
										shape="round"
										style={{
											fontSize: '1.4rem',
											fontWeight: 600,
											height: '5rem',
											width: '13rem',
											'--padding-left': 0,
											'--background': '#000000',
											'--background-activated': '#000000',
											'--background-hover': '#000000',
											'--color': '#FFFFFF'

										}}
									>
										Download Now
									</IonButton>
								</IonCol>
							</IonRow>
						</IonCol>

						<IonCol>
							<IonRow style={{ marginTop: '5rem' }}>
								<IonCol style={{ textAlign: 'center' }}>
									<IonRow >
										<IonCol size={0.25} />
										<IonCol className='monthly' style={{ borderRadius: '4rem', textAlign: 'left' }}>
											<IonRow>
												<IonCol style={{ padding: '2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '3rem',
														color: '#FFFFFF'
													}}>
														Monthly Plan
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '-3rem', }}>
												<IonCol style={{ padding: '2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '1.4rem',
														color: '#FFFFFF'
													}}>
														Designed for travelers or those seeking a reservation for a special occasion.
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '3rem', }}>
												<IonCol style={{ padding: '0rem 2rem 0rem 2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '1.4rem',
														color: '#FFFFFF'
													}}>
														$9/month
													</span>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol style={{ padding: '0rem 0rem 1rem 1rem' }}>
													<IonButton
														onClick={() => (toDownload())}
														shape="round"
														style={{
															marginTop: '1rem',
															fontSize: '1.4rem',
															fontWeight: 600,
															height: '5rem',
															width: '13rem',
															'--padding-left': 0,
															'--background': '#F6F1EF',
															'--background-activated': '#F6F1EF',
															'--background-hover': '#F6F1EF',
															'--color': '#000000'

														}}
													>
														Download Now
													</IonButton>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={0.25} />
									</IonRow>
								</IonCol>

								<IonCol style={{ textAlign: 'center' }}>
									<IonRow >
										<IonCol size={0.25} />
										<IonCol className='annual' style={{ borderRadius: '4rem', textAlign: 'left' }}>
											<IonRow>
												<IonCol style={{ padding: '2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '3rem',
														color: '#FFFFFF'
													}}>
														Annual Plan
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '-3rem', }}>
												<IonCol style={{ padding: '2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '1.4rem',
														color: '#FFFFFF'
													}}>
														Designed for foodies or those who love to frequent NYC's fine dining scene.
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '3rem', }}>
												<IonCol style={{ padding: '0rem 2rem 0rem 2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '1.4rem',
														color: '#FFFFFF'
													}}>
														$49/year
													</span>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol style={{ padding: '0rem 0rem 1rem 1rem' }}>
													<IonButton
														onClick={() => (toDownload())}
														shape="round"
														style={{
															marginTop: '1rem',
															fontSize: '1.4rem',
															fontWeight: 600,
															height: '5rem',
															width: '13rem',
															'--padding-left': 0,
															'--background': '#F6F1EF',
															'--background-activated': '#F6F1EF',
															'--background-hover': '#F6F1EF',
															'--color': '#000000'

														}}
													>
														Download Now
													</IonButton>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={0.25} />
									</IonRow>
								</IonCol>
							</IonRow>


							<IonRow style={{ marginTop: '2rem' }}>
								<IonCol style={{ textAlign: 'center' }}>
									<IonRow >
										<IonCol size={0.25} />
										<IonCol className='business' style={{ borderRadius: '4rem', textAlign: 'left' }}>
											<IonRow>
												<IonCol style={{ padding: '2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '3rem',
														color: '#FFFFFF'
													}}>
														For Business
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '-3rem', }}>
												<IonCol style={{ padding: '2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '1.4rem',
														color: '#FFFFFF'
													}}>
														Get a discounted rate when you equip your whole team with TableOne. Perfect for client dinners or as a perk.
													</span>
												</IonCol>
											</IonRow>

											<IonRow style={{ marginTop: '3rem', }}>
												<IonCol style={{ padding: '0rem 2rem 0rem 2rem' }}>
													<span style={{
														fontFamily: "'Nunito Sans', sans-serif",
														fontSize: '1.4rem',
														color: '#FFFFFF'
													}}>
														Minimum 5 team members
													</span>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol style={{ padding: '0rem 0rem 1rem 1rem' }}>
													<IonButton
														onClick={() => (toUrl("https://app.formbricks.com/s/cm37i33lv000dgvqxfu4fddan"))}
														shape="round"
														style={{
															marginTop: '1rem',
															fontSize: '1.4rem',
															fontWeight: 600,
															height: '5rem',
															width: '13rem',
															'--padding-left': 0,
															'--background': '#F6F1EF',
															'--background-activated': '#F6F1EF',
															'--background-hover': '#F6F1EF',
															'--color': '#000000'

														}}
													>
														Contact Us
													</IonButton>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={0.25} />
									</IonRow>
								</IonCol>
							</IonRow>


						</IonCol>
					</IonRow>

					<IonRow
						style={{
							height: '75rem',
						}}
						className='news2big'
					>
						<IonCol style={{ textAlign: 'center' }}>
							<IonRow style={{ marginTop: '30rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '2rem',
										color: '#FFFFFF'
									}}>
										“My new plan is to lean on <span style={{ color: "#F2C498" }}>TableOne</span>.”<br /><br />

									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ height: '12rem', marginTop: '25rem' }} onClick={() => (toUrl("https://gothamist.com/arts-entertainment/dinner-reservations-at-tatiana-are-impossible-to-get-so-i-spent-a-month-trying"))} />
						</IonCol>
					</IonRow>


					<IonRow style={{ background: '#F6F1EF', height: '30rem', textAlign: 'center' }} className='center-row'>
						<IonCol>
							<IonRow>
								<IonCol>

									<IonButton
										onClick={() => (toDownload())}
										shape="round"
										style={{
											fontSize: '1.4rem',
											fontWeight: 600,
											height: '5rem',
											width: '13rem',
											'--padding-left': 0,
											'--background': '#000000',
											'--background-activated': '#000000',
											'--background-hover': '#000000',
											'--color': '#FFFFFF'

										}}
									>
										Download Now
									</IonButton>
								</IonCol>

							</IonRow>

							<IonRow style={{ marginTop: '5rem', height: '5rem' }}>
								<IonCol>
									<IonRow>
										<IonCol>
											<img src={tableoneBlue} style={{ width: '15rem' }} />
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '1.4rem',
												color: '#000000'
											}}>
												TableOne Solutions Inc. © 2024
											</span>
										</IonCol>
									</IonRow>
								</IonCol>
							</IonRow>

						</IonCol>
					</IonRow>




				</IonGrid>
			</IonContent >
		</IonPage >
	)

}

export { ViewNewDesktop };
