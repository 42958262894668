import * as Realm from "realm-web";

// MongoDB Setup
// https://docs.mongodb.com/guides/realm/react-web-quickstart/
// https://docs.mongodb.com/realm/sdk/node/examples/authenticate-users/#anonymous-user
const REALM_APP_ID = "web-zinnowb"; // e.g. myrealm-abcde 
const realm = new Realm.App({ id: REALM_APP_ID });

async function doLogin(user) {
  // https://www.mongodb.com/docs/realm/sdk/node/examples/authenticate-users/#custom-function-user
  let creds = Realm.Credentials.function({
    user: user
  });

  return await realm.logIn(creds);
}

async function doLoginAnon() {
  let creds = Realm.Credentials.anonymous();

  return await realm.logIn(creds);
}

async function doInsertReferral(referrer, email) {  
  // https://www.mongodb.com/docs/realm/sdk/node/examples/authenticate-users/#custom-function-user
  let json = {
    referrer: referrer,
    email: email,
  }

  const res = await realm.currentUser.functions.saveReferral(json);
  console.log('RES: ', res)

  return
}

async function doInsertCheckout(deviceId) {  
  // https://www.mongodb.com/docs/realm/sdk/node/examples/authenticate-users/#custom-function-user
  let json = {
    deviceId: deviceId,
  }

  const res = await realm.currentUser.functions.insertCheckout(json);
  console.log(res)

  return res
}

async function doInsertPartner(partner, ip, device, sessionId) {  
  // https://www.mongodb.com/docs/realm/sdk/node/examples/authenticate-users/#custom-function-user
  let json;

  if (ip) {
    json = {...ip}
    delete json['IPv4'];
    json.ip = ip.IPv4;
  } else {
    json = {ip: 'unknown'}
  }

  json.partner = partner;
  json.partner = partner;
  json.device = device;
  json.sessionId = sessionId;
  json.time = (new Date()).toISOString()

  const res = await realm.currentUser.functions.insertPartner(json);

  return res
}

async function doInsertClick(sessionId) {  
  // https://www.mongodb.com/docs/realm/sdk/node/examples/authenticate-users/#custom-function-user
  let json = {
    sessionId: sessionId,
    time: (new Date()).toISOString()
  }

  const res = await realm.currentUser.functions.insertClick(json);

  return res
}

export {
  realm,
  doLogin,
  doLoginAnon,
  doInsertReferral,
  doInsertCheckout,
  doInsertPartner,
  doInsertClick
};